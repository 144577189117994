import { Ref } from 'vue';
import { UIKitComponent } from '../types/components';
import isWebcomponent from '../utils/isWebcomponent';
import isCsr from '../utils/isCsr';
import getValidOrigin from '../utils/getValidOrigin';

interface UIUseUIKit {
  host: HTMLElement | null;
  originHost: string | null;
  markAsLoaded: () => void;
}

type UIUseUIKitAssets = {
  src: string;
  target: 'shadow' | 'document';
}

type UIUseUIKitOptions = {
  componentHasStyles?: boolean,
  usedExternal?: boolean,
  loadImmediately?: boolean,
}

export default async function useUIKit (
  element: Ref<HTMLElement | undefined>,
  componentName?: UIKitComponent,
  initOptions?: UIUseUIKitOptions,
): Promise<UIUseUIKit> {
  let host: HTMLElement|null = null;
  const options: UIUseUIKitOptions = {
    componentHasStyles: true,
    usedExternal: false,
    loadImmediately: true,
    ...initOptions,
  }

  const markAsLoaded = (): void => {
    host && host.classList.add('--loaded');
  }

  if (element.value?.parentNode instanceof ShadowRoot) {
    host = element.value.parentNode.host as HTMLElement;
  } else if (element.value) {
    host = element.value.parentElement as HTMLElement;
  }

  const originHost = getValidOrigin();

  if (originHost && typeof window.uikitBase === 'undefined') {
    window.uikitBase = originHost;
  }

  if (isWebcomponent(element.value)) {
    const version = window.uikit?.version ?
      `.${window.uikit.version}` :
      APP_VERSION ?
        `.${APP_VERSION}` :
        '';

    const resetAsset = `/assets/ui-reset${version}.css`;
    const iconsFont = `/assets/ui-kit/fonts/ui-icons${version}.css`;
    const assets: Array<UIUseUIKitAssets> = [];

    if (options.usedExternal && isCsr) {
      assets.push({
        src: `/assets/ui-kit${version}.css`,
        target: 'shadow',
      });
      assets.push({
        src: `/assets/ui-kit${version}.css`,
        target: 'document',
      });
    } else {
      const style = document.createElement('style');
      style.innerText = document.querySelector(
        '#ui-kit-critical-style',
      )?.textContent?.replace(/\n/g, '') as string;
      element.value && (element.value.parentNode as ShadowRoot)?.appendChild(style);
    }

    componentName &&
    options.componentHasStyles &&
    assets.push({
      src: `/assets/ui-kit/${componentName}${version}.css`,
      target: 'shadow',
    });

    if (element.value?.parentNode instanceof ShadowRoot) {
      assets.push({
        src: resetAsset,
        target: 'shadow',
      });
      assets.push({
        src: iconsFont,
        target: 'shadow',
      });

      if (options.usedExternal) {
        assets.push({
          src: iconsFont,
          target: 'document',
        });
      }
    }

    const promises: Promise<void>[] = [];
    if (isCsr) {
      assets.forEach((link) => {
        promises.push(new Promise((resolve) => {
          const asset = document.createElement('link');
          asset.rel = 'stylesheet';
          asset.href = window.uikitBase ? `${window.uikitBase}${link.src}` : link.src;
          if (link.target === 'document') {
            asset.id = `ui_${btoa(link.src).replace(/[^a-zA-Z0-9]/g, '')}`;
            if (!document.head.querySelector(`#${asset.id}`)) {
              document.head.appendChild(asset);
            } else {
              resolve();
            }
          } else {
            if (element && element.value) {
              (element.value.parentNode as ShadowRoot).appendChild(asset);
            }
          }
          asset.onload = (): void => resolve();
        }))
      })
    }

    await Promise.all(promises);

    options.loadImmediately && markAsLoaded();
  }

  return {
    host,
    originHost,
    markAsLoaded,
  }
}
